import React from 'react'

export default function Paused() {
    return (
        <svg width="5" height="6" viewBox="0 0 5 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="1.5" height="6" fill="white" />
            <rect x="3" width="1.5" height="6" fill="white" />
        </svg>


    )
}
