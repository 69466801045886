import React from 'react'

export default function Left() {
  return (
    <svg width="25" height="19" viewBox="0 0 25 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="path-1-inside-1_178_746" fill="white">
    <path d="M12.6998 1.71696L19.9023 8.28517L0 8.28517L0 10.7148L19.9023 10.7148L12.6998 17.283L14.5826 19L25 9.5L14.5826 -1.90735e-06L12.6998 1.71696Z"/>
    </mask>
    <path d="M12.6998 1.71696L19.9023 8.28517L0 8.28517L0 10.7148L19.9023 10.7148L12.6998 17.283L14.5826 19L25 9.5L14.5826 -1.90735e-06L12.6998 1.71696Z" fill="#C4C4C4"/>
    <path d="M12.6998 1.71696L10.0045 -1.23861L6.76354 1.71696L10.0045 4.67253L12.6998 1.71696ZM19.9023 8.28517V12.2852H30.2249L22.5976 5.32959L19.9023 8.28517ZM0 8.28517L0 4.28517H-4L-4 8.28517H0ZM0 10.7148H-4L-4 14.7148H0L0 10.7148ZM19.9023 10.7148L22.5976 13.6704L30.2249 6.71483L19.9023 6.71483L19.9023 10.7148ZM12.6998 17.283L10.0045 14.3275L6.76354 17.283L10.0045 20.2386L12.6998 17.283ZM14.5826 19L11.8873 21.9556L14.5826 24.4135L17.2779 21.9556L14.5826 19ZM25 9.5L27.6953 12.4556L30.9363 9.5L27.6953 6.54443L25 9.5ZM14.5826 -1.90735e-06L17.2779 -2.95557L14.5826 -5.4135L11.8873 -2.95557L14.5826 -1.90735e-06ZM10.0045 4.67253L17.207 11.2407L22.5976 5.32959L15.3951 -1.23861L10.0045 4.67253ZM19.9023 4.28517L0 4.28517L0 12.2852L19.9023 12.2852L19.9023 4.28517ZM-4 8.28517L-4 10.7148H4V8.28517H-4ZM0 14.7148L19.9023 14.7148L19.9023 6.71483L0 6.71483L0 14.7148ZM17.207 7.75926L10.0045 14.3275L15.3951 20.2386L22.5976 13.6704L17.207 7.75926ZM10.0045 20.2386L11.8873 21.9556L17.2779 16.0444L15.3951 14.3275L10.0045 20.2386ZM17.2779 21.9556L27.6953 12.4556L22.3047 6.54443L11.8873 16.0444L17.2779 21.9556ZM27.6953 6.54443L17.2779 -2.95557L11.8873 2.95557L22.3047 12.4556L27.6953 6.54443ZM11.8873 -2.95557L10.0045 -1.23861L15.3951 4.67254L17.2779 2.95557L11.8873 -2.95557Z" fill="#C4C4C4" mask="url(#path-1-inside-1_178_746)"/>
    </svg>
    
  )
}
