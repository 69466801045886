import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import Layout from '../layout'
import Logo from '../logo'
import { useStaticQuery, graphql } from 'gatsby'

import AuthContext from '../../context/auth'
import Button from '../button'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'
export default function SignIn() {

    const { signin, submitEmail } = useContext(AuthContext)
    const [signInAspect, setSignInStage] = useState('email')


    const data = useStaticQuery(graphql`
        query SignIn {
            datoCmsSigninPage {
                name
                description
            }
      }
      `)

    return (
        <Layout>

            <LoginWrapper>
                <div className='p-20 md:p-0  md:flex md:justify-center'>
                    <Logo fill='white' />
                </div>

                <div className='flex flex-col items-center justify-center md:pt-30 md:pb-30 '>
                    <div className='max-w-440 flex flex-col items-center'>
                        <h2 className='h2 text-center white mb-40 max-w-[300px] '>{data.datoCmsSigninPage.name}</h2>
                        <ReactMarkdown className='h3 text-center white mb-40 links-underline p-mb-10'>
                            {data.datoCmsSigninPage.description}
                        </ReactMarkdown>
                        <div className='flex justify-center mb-40 '>
                            <Stage signInAspect={signInAspect} signin={signin} submitEmail={submitEmail} setStage={setSignInStage} />
                        </div>

                        <h3 className='white h3'>Having trouble? Contact <a href='mailto:marni.duffy@angloamerican.com' className='underline'>Admin</a></h3>
                    </div>
                </div>


                <div></div>
            </LoginWrapper>
        </Layout >
    )
}

function Stage({ signInAspect, signin, submitEmail, setStage }) {

    switch (signInAspect) {

        case 'email':
            return (
                <Button name='email' key={'email'} onSubmit={(e) => submitEmail(e, setStage('password'))}>
                    <input type="hidden" name="form-name" value="email" />
                    <input name='email' type='email' required={true} placeholder='Enter Email' />
                </Button>
            )
        case 'password':

            return (
                <Button key={'password'} onSubmit={signin}>
                    <input name='password' type='password' required={true} placeholder='Enter Site Password' />
                </Button>
            )
        default:
            return null
    }
}




const LoginWrapper = styled.div`
    min-height:100vh;
    background:#252525 ;
    display:grid ;
    grid-template-rows:75px 1fr 75px;
    @media(max-width:768px){
        grid-template-rows:60px 1fr 60px;
        padding:30px ;
      
    }

`
