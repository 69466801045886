import React from 'react'
import styled from 'styled-components'
import Left from '../icons/left'
export default function Button({ children, onSubmit = () => { }, ...props }) {
    return (
        <form onSubmit={onSubmit} {...props} >
            <Login >
                {children}
                <button>
                    <div>
                        <Left />
                    </div>
                </button>
            </Login>
        </form>
    )
}





const Login = styled.div`
    display:flex ;
    background: #F2F2F2;
    border-radius: 5px;
   -webkit-transform: translateZ(0);
    overflow:hidden;
    position:relative ;


    
    input,.h2{
        background: #F2F2F2;
        padding:20px;
        padding-left:45px;
        padding-right:45px;
        max-width:290px;
        text-align:center;
        &:focus{
            outline:none;
        }
        &::placeholder{
            color:black ;
        }

        @media(max-width:800px){
            width:100%;
        }
    }

    input:focus::placeholder {
        color: transparent;
    }   
 

    button{
        position:absolute ;
        right:15px ;
        transform:translate(0%, -50%) ;
        top:50%;
        background: #F2F2F2;
        div{
            height:26px ;
            width:26px;
            padding:9px;
            background:white ;
            transition:0.5s ease background ;
            border-radius:50%;
           -webkit-transform: translateZ(0);
        }
        svg{
            height:9px;
            width:9px;
            path{
                fill:black ;
                transition:0.5s ease fill ;
            }
        }
        &:hover{
            svg{
                path{
                    fill:white
                }
            }
            div{
                background:black;
            }
        }
     
       
    }

`

