import { graphql, useStaticQuery } from "gatsby"
import * as React from "react"
import Header from "../header"
import HeaderTitle from "../header-title"
import Footer from "../footer"
import Layout from "../layout"
import Tabs from "../tabs"
import Up from "../../icons/up"
const Photos = () => {

  const data = useStaticQuery(graphql`
query Photos {
    
    datoCmsPhoto {
      description
      categories{
        zipFile{
              url
              size
        }
        name
          images {
            title
            url
            width
            height
            notes
            gatsbyImageData(imgixParams: {auto:"compress",w:"1400",h:"1600",q:45})
          
            highRes:sizes(imgixParams: { dl: "", fm: "jpg",q:85}) {
              src
            }
            lowRes:sizes(imgixParams: {q: 45, dl: "", fm: "jpg" }) {
              src
            }
          }
      }
    }
  }
  `)

  return (
    <Layout>
      <Header title='Photos' className='md:hidden' downloadAble={true} categories={data.datoCmsPhoto.categories} />
      <HeaderTitle className='max-w-620' content={data.datoCmsPhoto.description} />
      <Tabs categories={data.datoCmsPhoto.categories} />
      <Footer>
        <button onClick={() => { window.scrollTo({ left: 0, top: 0, behavior: 'smooth' }) }} className="flex  items-center ">
          <Up />
          <h1 className='h1 ml-20'>Back to top</h1>
        </button>
      </Footer>
    </Layout>
  )
}

export default Photos

