import React from 'react'

export default function Play() {
  return (
    <svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8 5L0.5 9.33013L0.5 0.669872L8 5Z" fill="white"/>
    </svg>
    
  )
}
