import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react'
import styled from "styled-components";
import Down from '../icons/down';

export default function Pdf({ pdfs }) {

  return (
    <div className='p-40 pt-0 md:p-30 md:pt-50    '>
      <div className='flex flex-col '>
        {pdfs.map((pdf, index) => {
          return <PdfDisplay key={index} className='mb-20 md:mb-30 ' pdf={pdf} />
        })}
      </div>

    </div>
  )
}


function PdfDisplay({ pdf, className }) {

  return (
    <PdfWrapper className={className}  >
      <Overlay className='z-[2] md:!p-[20px]'  >
        <div className='text'>
          <h3 className={' white  text-left h2 mb-20 md:text-[13px] md:font-medium '}>
            {pdf.name}
          </h3>
          <Description className={'h3 white description  max-w-250'}>
            {pdf.descriptionOfEbook}
          </Description>
        </div>

        <div className='w-[100%] flex justify-start h3'>
          <DownloadButton target="_blank" className='download mr-10 md:mr-0 hover' download href={pdf.downloadedAsset.url}>
            View PDF
          </DownloadButton>
          <DownloadButton download href={pdf.downloadedAsset.downloadLink} className='flex gap-10 md:hidden' >
            <h3 className='h3 white medium'>Download</h3>
            <div className='download'>
              <Down fill='black' />
            </div>
          </DownloadButton>

        </div>
      </Overlay>
     
        <GatsbyImage objectFit='cover'  className='w-full h-full ' objectPosition='center center' image={pdf.thumbnail.gatsbyImageData} />

    </PdfWrapper>
  )
}

const PdfWrapper = styled.div`
  max-width:600px;
  margin-left:auto;
  margin-right:auto;
  position:relative ;
  overflow:hidden ;
  border-radius: 5px;
 -webkit-transform: translateZ(0);
`

const Description = styled.p`
  text-align:left;

 
`

const DownloadButton = styled.a`
  opacity:0 ;
  transition: 1s ease all ;
  ${PdfWrapper}:hover & {
    opacity:1 ;
  }
  align-items:center;


  svg{
    path,circle,rect{
      transition: 0.5s ease fill ;
    }
  }
  .download{
    transition: 1s ease background-color ;
  }

  &:hover{
    &.hover{
    background:black ;
        color:white;
    }
    svg{
      path{
        fill:white ;
      }
    }
    .download{
      background-color:black ;
    }
  }
  @media(max-width:800px){
      opacity:1 ;
  }
`

const Overlay = styled.div`

  padding:${props => props.none ? 0 : 25}px;
  position:absolute ;
  top:0px ;
  width:100% ;
  height:100% ;
  display:flex ;
  flex-direction:column;
  justify-content:space-between;
  transition:0.5s ease opacity;
  background-color:#00000020 ;
  
  @media(max-width:800px){

  .description{
    display:none ;
  }
}
  

`

