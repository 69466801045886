import React from "react"
import styled, { keyframes } from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
export default function ImageWrapper({ image, title, className, index, lowRes, highRes, single = false, href, text = 'File' }) {


    return (
        <Wrapper index={index} className={className} >

            <Overlay>
                <div>
                    {title ? <div className='h3 medium white mb-10'>{title}</div> : null}
                    <Description small={true} className={'h3 white max-w-250'}>
                        {image.notes}
                    </Description>
                </div>
                <div className='flex items-center  h3 '>
                    {!text ? <h3 className='h3 medium white'>{!text ? 'Download' : ''}</h3> : null}
                    {!single ?
                        <>
                            <DownloadButton className='download mr-10' download href={lowRes}>
                                Lo
                            </DownloadButton>
                            <DownloadButton className='download mr-10' download href={highRes}>
                                Hi
                            </DownloadButton>
                        </>
                        : <DownloadButton className='download mr-10' target="_blank" download href={href}>
                            {text}
                        </DownloadButton>}
                </div>
            </Overlay>
            <GatsbyImage objectFit="cover" className="h-full w-full" alt='' image={image.gatsbyImageData} />
        </Wrapper>
    )
}


const fade = keyframes`
    from {
        opacity:0;
    }
    
    to{
        opacity:1 ;
    }
`
const Description = styled.p`
  @media(max-width:1200px){
    display:${props => props.small ? 'none' : ''} ;
  }
  text-align:left;

 
`




const Wrapper = styled.div`
    position:relative ;
    border-radius: 5px;
   -webkit-transform: translateZ(0);
    overflow:hidden;
    animation: ${fade} 0.5s ease ;



`






const Overlay = styled.div`
    position:absolute ;
    top:0px ;
    width:100%;
    height:100%;
    padding:20px;
    display:flex ;
    flex-direction:column;
    justify-content:space-between;
    z-index:10;
    opacity:0;
    background-color:transparent ;
    transition:1s ease opacity, 1s ease background-color ;
    ${Wrapper}:hover &{
        background-color:#00000020 ;
        opacity:1
    }
`

const DownloadButton = styled.a`

    transition: 1s ease all;

    &:hover{
        background:black ;
        color:white;
    }

`

