
import { navigate } from "gatsby";
import React, { createContext, useEffect, useLayoutEffect, useState } from "react";

const auth = false;

const AuthContext = createContext(auth)

export default AuthContext

export function AuthContextWrapper({ children }) {


    // really bad implenation do not copy

    const [user, setUser] = useState(auth)

    async function signin(e) {
        e.preventDefault()

        const formData = new FormData(e.target.closest('form'));

        if (formData.get('password') === 'Grasstree2022!') {
            setUser(true)
            localStorage.setItem('user', true)

        } else {
            setUser(false)
        }

    }

    async function submitEmail(e, fn = () => { }) {
        e.preventDefault()

        try {
            const formData = new FormData(e.target.closest('form'));

            if (formData.get('email')) {
                localStorage.setItem('user-email', true)
                const res = await fetch("/", {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded"
                    },
                    body: new URLSearchParams(formData).toString(),
                })
                if (res.status > 299) {
                    throw Error('Invalid response', res.status)
                }
                fn()
            }
        } catch (e) {
            console.log(e)
        }
    }

   



    useLayoutEffect(() => {

        if (localStorage.getItem('user')) {
            setUser(true)
        }


    }, [])

    return (
        <AuthContext.Provider value={{ user, signin, submitEmail, }}>
            {children}
        </AuthContext.Provider>
    )
}

