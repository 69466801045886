import React from 'react'
import Header from '../header'
import Layout from '../layout'
import HeaderTitle from '../header-title'
import { useStaticQuery, graphql } from 'gatsby'
import Footer from '../footer'
import Up from '../../icons/up'
import Pdf from '../pdf'
export default function Ebook() {
    const data = useStaticQuery(graphql`
    query Ebook {
        datoCmsEbookPage {
            description
            name
            descriptionOfEbook
            thumbnail {
                gatsbyImageData
            }
            downloadedAsset {
                url
                downloadLink:url(imgixParams: {dl: "Greentree ebook"})
            }
       }
   }
   `)

    return (
        <Layout>
            <Header title='Ebook' className='md:hidden' href={false} />
            <HeaderTitle className='max-w-620' content={data.datoCmsEbookPage.description} />
            <Pdf pdfs={[data.datoCmsEbookPage]} />
            <Footer>
                <button onClick={() => { window.scrollTo({ left: 0, top: 0, behavior: 'smooth' }) }} className="flex items-center ">
                    <Up />
                    <h1 className='h1 ml-20'>Back to top</h1>
                </button>
            </Footer>
        </Layout>
    )
}
