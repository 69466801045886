import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import Header from '../header'
import Layout from '../layout'
import Left from '../../icons/left'
import { Link } from '@reach/router'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'

export default function Intro() {

    const data = useStaticQuery(graphql`
    query Intro {
        datoCmsIntroPage {
            introCredits
            intro
            introTitle
            introImage {
                gatsbyImageData
            }
        }
  }
  `)

    return (
        <Layout>
            <Header content={false} />
            <div className='flex pl-30 pr-30 justify-center pb-110 pt-110 md:pt-60 flex-col align-center '>
                <div className=' max-w-[600px] flex flex-col justify-center m-[auto]'>
                    <GatsbyImage className='mb-40 w-full' image={data.datoCmsIntroPage.introImage.gatsbyImageData} />
                    <h2 className='text-center mb-40 grey h2'>
                        {data.datoCmsIntroPage.introTitle}

                    </h2>
                    <ReactMarkdown className='h3 grey links-underline p-mb-10 pb-[1rem]'>
                        {data.datoCmsIntroPage.intro}
                    </ReactMarkdown>
                    <p className='h3 grey mb-40'>
                        <div className='text-center'>
                            Remember The Moment.
                            <br />
                            <br />
                            {data.datoCmsIntroPage.introCredits}
                        </div>
                    </p>
                    <div className='flex justify-center mb-40'>
                        <EnterSite to='/photos'>
                            <h2 className='h2 black'>Enter Site </h2>
                            <div className='enter-button'>
                                <div>
                                    <Left />
                                </div>
                            </div>
                        </EnterSite>
                    </div>
                    <p className='h3 grey text-center mb-40'>Or click below to navigate through the online gallery sections</p>


                </div>
                <div className='flex justify-center md:flex-col  md:max-w-[240px] m-[auto]'>
                    <Link className='mr-5 ml-5 page-link  md:mb-[10px] md:text-center' to='/photos'>
                        Photos
                    </Link>
                    <Link className='mr-5 ml-5 page-link md:mb-[10px] md:text-center' to='/films'>
                        Films
                    </Link>
                    <Link className='mr-5 ml-5 page-link md:mb-[10px] md:text-center' to='/other-photos'>
                        Other Photos
                    </Link>
                    <Link className='mr-5 ml-5 page-link md:mb-[10px] md:text-center' to='/presentations'>
                        Presentation
                    </Link>
                    <Link className='mr-5 ml-5 page-link md:text-center' to='/ebook'>
                        Grasstree Mine History E-book
                    </Link>
                </div>
            </div>

        </Layout>
    )
}





const EnterSite = styled(Link)`
    display:flex ;
    background: #F2F2F2;
    border-radius: 5px;
   -webkit-transform: translateZ(0);
    overflow:hidden;
    position:relative ;


    
    .h2{
        background: #F2F2F2;
        padding:25px;
        padding-left:75px;
        padding-right:75px;
        max-width:290px;
        text-align:center;
        &:focus{
            outline:none;
        }
        &::placeholder{
            color:black ;
        }

        @media(max-width:800px){
            width:100%;
        }
    }

 

    .enter-button{
        position:absolute ;
        right:15px ;
        transform:translate(0%, -50%) ;
        top:50%;
        background: #F2F2F2;
        div{
            height:26px ;
            width:26px;
            padding:9px;
            background:white ;
            transition:0.5s ease background ;
            border-radius:50%;
           -webkit-transform: translateZ(0);
        }
        svg{
            height:9px;
            width:9px;
            path{
                fill:black ;
                transition:0.5s ease fill ;
            }
        }
      
     
       
    }
    &:hover{
       .enter-button{
            svg{
                path{
                    fill:white
                }
            }
            div{
                background:black;
            }
        }
    }

`
