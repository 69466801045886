import React from 'react'
import Icon from '../icons/logo'

export default function Logo({ fill,className }) {
    return (
        <div className="flex items-center md:flex-col">
            <Icon fill={fill} />
            <h3 className={"h3 pl-20 medium md:mt-10  md:hidden " + className}>Remember The Moment</h3> 
        </div>
    )
}
