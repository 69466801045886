import React from 'react'
import Layout from '../layout'
import Header from '../header'

import HeaderTitle from '../header-title'
import Films from '../films'
import Footer from '../footer'
import { graphql, useStaticQuery } from 'gatsby'
import Up from '../../icons/up'

export default function OtherPhotos() {
    const data = useStaticQuery(graphql`
     query Film {
        datoCmsFilmPage {
            description
            categories{
                name
                videoLink
                downloadLink
                description
                thumbnail {
                    gatsbyImageData
                    url
                }
            }
        }
    }
    `)
    return (
        <Layout>

            <Header className='md:hidden' />
            <HeaderTitle className='max-w-440' content={data.datoCmsFilmPage.description} />
            <Films films={data.datoCmsFilmPage.categories} />
            <Footer>
                <button onClick={() => { window.scrollTo({ left: 0, top: 0, behavior: 'smooth' }) }} className="flex items-center ">
                    <Up />
                    <h1 className='h1 ml-20'>Back to top</h1>
                </button>
            </Footer>
        </Layout>
    )
}
