import React from 'react'
import Video from './video'
export default function Films({ films }) {

  return (
    <div className='p-40 md:p-30 md:pt-50   '>
      <div className='flex flex-col '>
        {films.slice(0, 2).map((film, index) => {
          return <Video key={index} className='mb-20 md:mb-30 ' film={film} />
        })}
      </div>
      <div className='grid-cols-3 grid gap-20  md:gap-30 md:grid-cols-1'>
        {films.slice(2).map((film, index) => {
          return <Video key={('small' + index)} small={true} film={film} />
        })}
      </div>
    </div>
  )
}
