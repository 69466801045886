import React, { useCallback, useEffect, useState } from "react"
import styled from "styled-components"
import Logo from './logo'
import { Link, useStaticQuery, graphql } from "gatsby"
import Down from "../icons/down"
import Cross from "../images/cross"
import { ReactMarkdown } from "react-markdown/lib/react-markdown"


const Header = ({ href, className, title, categories = [], downloadAble = false }) => {

  const [open, setOpen] = useState(false)
  const [menu, setMenu] = useState(false)
  const [height, setHeight] = useState(false)
  const menuRef = useCallback((node) => {
    if (node !== undefined) {
      setHeight(node?.offsetHeight)
    }
  }, [])
  const [download, setDownload] = useState(false)


  useEffect(() => {
    if (window.innerWidth < 768 && menu) {
      window.scrollTo({ top: 0,behavior:'smooth' })
    }
  }, [menu])
  return (
    <>
      {downloadAble ? <div className={"w-[100vw] flex h-[100vh] fixed top-0 left-0 z-[100] justify-end " + (!download ? 'pointer-events-none' : 'pointer-events-auto')}>
        <button onClick={() => setDownload(false)} className={"w-[100%] transition-opacity duration-500 absolute top-0 h-full bg-[#ffffff50]   " + (!download ? 'opacity-0 ' : 'opacity-100 ')} />
        <div className={"flex-grow-1 h-full overflow-scroll bg-[#F2F2F2] p-[30px] min-w-[50%] md:w-full transition-transform  duration-500 " + (!download ? 'translate-x-full' : 'translate-x-0')}>

          <div className="flex justify-between mb-[50px] h4 font-medium !text-[#767676]">
            <span >
              Download All
            </span>
            <button onClick={() => setDownload(false)}>
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <line x1="0.353553" y1="0.646447" x2="13.3536" y2="13.6464" stroke="#767676" />
                <line x1="0.646447" y1="13.6464" x2="13.6464" y2="0.646446" stroke="#767676" />
              </svg>
            </button>

          </div>
          <div >
            {categories.map((category) => {
              return (
                <div className="grid grid-cols-[1fr_0.3fr_0.5fr] md:grid-cols-[0.75fr_0.5fr_0.5fr] mb-[20px] h3 font-medium !text-[#767676] gap-x-[10px]">
                  <div>
                    {category.name}
                  </div>
                  <div className="flex justify-end md:justify-start  ">
                    {category?.zipFile?.size ? `${(category?.zipFile?.size / 1_000_000).toFixed(1)}Mb` : null}
                  </div>
                  <div className="flex justify-end">
                    {category?.zipFile?.url ? <a href={category?.zipFile?.url} className='hover-underline '>
                      Download File
                    </a> : null}

                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div> :null}
      <HeaderWrapper>
        <Link to='/' className="flex items-center md:hidden">
          <Logo className={className} />
        </Link>

        <div className="flex justify-between md:hidden">
          <Link className="h3 medium li-link mr-15" activeClassName="li-underline " to='/photos'>Photos</Link>
          <Link className="h3 medium li-link mr-15" activeClassName="li-underline" to='/films'>Films</Link>
          <Link className="h3 medium li-link mr-15" activeClassName="li-underline" to='/other-photos'>Other Site Photos</Link>
          <Link className="h3 medium li-link mr-15" activeClassName="li-underline" to='/presentations'>Presentation</Link>
          <Link className="h3 medium li-link" activeClassName="li-underline" to='/ebook'>Grasstree Mine History E-book</Link>
        </div>

        <div className="flex justify-end items-center md:justify-between">
          <Link to='/' className=" items-center hidden md:block  ">
            <Logo className={className} />
          </Link>

          <div className="h-[37px] md:hidden"/>
          <Download hidden={!downloadAble} className="flex-col md:hidden relative">
            <button onClick={() => setDownload((state) => !state)} className="flex justify-center items-center p-[10px]">
              Download Assets  <div className={'ml-[10px] ' + (download ? 'rotate-180' : '')}><Down /></div>
            </button>
            
          </Download>
          <div className="flex items-center">
            <button onClick={() => setOpen((state) => !state)} className="h3 hidden md:block mr-[20px] ">Info</button>
            <Open className="md:hidden" onClick={() => setOpen((state) => !state)} open={open}>
              <span />
            </Open>
            <Open className="hidden md:block" onClick={() => setMenu((state) => !state)} open={menu}>
              <span />
            </Open>
          </div>
          <Overlay setOpen={setOpen} open={open} />

        </div>


      </HeaderWrapper>

      <div className={"hidden md:flex  justify-end md:p-30 md:pt-10 md:pb-10 items-center bg-[#FAFAFA]    " + (downloadAble ? '' : 'md:hidden')}>
        <h3 hidden={!downloadAble} className={"h3 mr-[10px] hidden " + (downloadAble ? ' sm:block' : '')}>Download all</h3>
        <Download hidden={!downloadAble} className="flex " onClick={() => setDownload(true)}>
          <span className="mr-10 sm:hidden"> Download</span> <Down />
        </Download>

      </div>
      <div style={{ height: (menu ? `${height}px` : 0) }} className="overflow-hidden hidden md:block transition-[height] ease duration-[500ms]">
        <div ref={menuRef} className="hidden md:flex flex-col md:p-30 md:pt-[10px] md:pb-[10px] items-start bg-[#FAFAFA]" >
          <Link className="h3 medium mr-15  li-link" activeClassName="li-underline" to='/photos'>Photos</Link>
          <Link className="h3 medium mr-15 li-link" activeClassName="li-underline" to='/films'>Films</Link>
          <Link className="h3 medium mr-15 li-link sm:mr-0" activeClassName="li-underline" to='/other-photos'>Other Site Photos</Link>
          <Link className="h3 medium  li-link sm:mr-0" activeClassName="li-underline" to='/presentations'>Presentation</Link>
          <Link className="h3 medium  li-link sm:mr-0" activeClassName="li-underline" to='/ebook'>Grasstree Mine History E-book</Link>
        </div>
      </div>

    </>
  )
}


function Overlay({ open, setOpen }) {
  const data = useStaticQuery(graphql`
  query DropDownInfo {
    datoCmsInfoDropdown {
      description
    name
  }
}
`)
  return (
    <>

      <OverlayWrapper open={open} >
        <div className="h-[100%] md:flex md:flex-col ">
          <div className="p-[30px] hidden  md:flex justify-between items-center ">
            <Link to='/' className="flex items-center">
              <Logo className='hidden' />
            </Link>
            <Cross onClick={() => setOpen(false)} />
          </div>
          <div className="md:flex-grow md:p-30 md:flex md:flex-col md:justify-center md:pb-110 justify-center ">
            <div className="max-w-440 md:flex md:flex-col md:justify-center md:max-w-full m-auto">
              <h2 className='h2 text-center white mb-40 '>{data.datoCmsInfoDropdown.name}</h2>
              <ReactMarkdown className='h3 text-center white mb-40 links-underline p-mb-10 '>
                {data.datoCmsInfoDropdown.description}
              </ReactMarkdown>

              <p className="h3 text-center white">
                Copyright (C) {new Date().getFullYear()}. Grasstree Mine
              </p>
            </div>
          </div>

        </div>
      </OverlayWrapper>
      <OverlayButton onClick={() => setOpen(false)} open={open} />
    </>

  )
}


export default Header

const OverlayWrapper = styled.div`
  background-color:#3F3F3F ;
  padding:60px ;
  display:flex ;
  flex-direction:column;
  justify-content:center;
  position:fixed ;
  top:0px ;
  width:100vw ;
  z-index:11;
  left:0px ;
  transition:1s ease transform ;
  transform:${props => props.open ? `translateY(0%)` : `translateY(-100%)`} ;
  @media(max-width:800px){  
    height:100% ;
    opacity:${props => props.open ? 1 : 0} ;
    transition:1s opacity ease ;
    min-height:100vh ;
    justify-content:unset;
   padding:0px ; 
   overflow:scroll ;
  }
`


const OverlayButton = styled.button`
  background:black;
  transition:0.5s ease opacity ;
  opacity:${props => props.open ? 0.9 : 0};
  pointer-events:${props => props.open ? 'all' : 'none'};;
  position:fixed ;
  top:0px;
  height:100vh;
  width:100vw;
  left:0px;

`


const HeaderWrapper = styled.header`
  position:sticky;
  padding:20px;
  display:grid ;
  grid-template-columns:1fr auto 1fr;
  align-items: center;
  column-gap:20px;
  top:0px ;
  z-index:12;
  background:white ;

  @media(max-width:800px){
    grid-template-columns:1fr;
    background:${props => props.background ? props.background : `#F0F0F0`} ;
    padding:30px;
    padding-top:12.5px;
    padding-bottom:12.5px;
  }

`

const Open = styled.button`
  height:10px ;
  width:16px ;
  position:relative ;
  z-index:12;
  @media(max-width:768px){
    z-index:2
  }
  span{
    position:absolute ;
    top:50%;
    height:1px ;
    width: 100%;
    background-color:#767676 ;
    left:0px ;
    opacity:${props => props.open ? 0 : 1}; 
    transition:0.5s ease opacity;
  }
  &::after, &::before{
    position:absolute ;
    left:0px ;
    content:'' ;
    height:1px ;
    width: 100%;
    background-color:#767676 ;
    transition:0.5s ease transform ;
  }
  &::before{
    top:100% ;
    transform: ${props => props.open ? 'translate(0px,-5px) rotate(45deg)' : ''}
  }
  &::after{
    top:0% ;
    transform: ${props => props.open ? 'translate(0px,5px) rotate(-45deg)' : ''}
  }

`

const Download = styled.button`

  background-color:#F2F2F2 ;
  border-radius: 5px;
 -webkit-transform: translateZ(0);
  justify-content:center;
  align-items:center;
  color:#767676 ;
  margin-right:25px;
  font-size: 12px;
  line-height: 16px;
  font-weight:500;
  cursor:pointer;
  transition:1s ease color, 1s ease  background-color  ;
  svg{
      path{
        transition:1s ease fill ;
      }
  }
  pointer-events:${props => props.hidden ? 'none' : 'all'};

  opacity:${props => props.hidden ? 0 : 1};


  &:hover{
    background-color:black;
    color:white;
 
    svg{
      path{
        fill:white;
      }
    }
  }

  @media(max-width:800px){
    margin-right:0px;
    padding:12.5px ;
    background-color:white ;
    border:1px solid #767676 ;
  }
`