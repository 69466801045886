import React from 'react'

export default function Logo({fill = "#767676"}) {
  return (
    <svg width="108" height="33" viewBox="0 0 108 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M22.0111 9.82208L26.9329 16.6268L34.5233 0.0137152L34.6752 0L42.3351 16.7738C41.5691 16.7738 40.9244 16.7933 40.2828 16.7561C40.1662 16.7493 40.0271 16.5269 39.9575 16.377C39.4755 15.3425 38.9974 14.306 38.5439 13.2587C38.3871 12.8972 38.1794 12.7434 37.7709 12.7464C35.6503 12.7653 33.5277 12.7653 31.4031 12.7464C31.0112 12.7464 30.8153 12.8923 30.6674 13.2362C30.2265 14.2541 29.7436 15.2523 29.3076 16.2731C29.144 16.6562 28.9256 16.8139 28.4984 16.8041C27.3571 16.7767 26.2148 16.7796 25.0696 16.8041C24.6777 16.812 24.4456 16.6699 24.2291 16.3535C22.933 14.4549 21.6173 12.569 20.3104 10.6793C20.1899 10.5059 20.0978 10.2806 19.9323 10.1757C19.7227 10.0466 19.4799 9.98151 19.2338 9.98862C19.1495 9.99352 19.0173 10.309 19.0163 10.4843C18.9996 12.3751 18.9918 14.2658 19.0163 16.1575C19.0222 16.6405 18.886 16.8531 18.3805 16.8031C18.0387 16.7804 17.6959 16.7778 17.3538 16.7953C17.0677 16.8012 16.9276 16.6797 16.9433 16.3868C16.9502 16.2575 16.9433 16.1262 16.9433 15.9949V0.648535C17.0726 0.601511 17.1481 0.550569 17.2215 0.550569C18.8164 0.601511 20.428 0.534894 22.0023 0.746501C24.2046 1.0404 25.6848 2.74011 25.7818 4.79544C25.8974 7.28084 24.7042 9.00798 22.4255 9.66239C22.3226 9.69178 22.2208 9.73979 22.0111 9.82208ZM19.0133 5.32641C19.0133 6.15619 19.0133 6.98596 19.0133 7.81671C19.0133 8.05869 19.0692 8.26344 19.3572 8.22621C20.3212 8.10375 21.3067 8.05379 22.2394 7.80496C23.2719 7.52967 23.7765 6.68912 23.8774 5.6497C23.9783 4.61028 23.7324 3.69038 22.7909 3.10259C21.7201 2.4276 20.5122 2.38156 19.2994 2.42858C19.2014 2.42858 19.032 2.69015 19.028 2.83514C18.9996 3.66491 19.0133 4.49566 19.0133 5.32641ZM34.6722 4.52701H34.5341L31.7979 10.7772H37.4055L34.6722 4.52701Z" fill={fill}/>
    <path d="M81.1796 32.7137C80.2979 32.7137 79.4348 32.7245 78.5767 32.6981C78.4895 32.6981 78.3494 32.4776 78.3327 32.3473C77.9947 29.8129 77.6724 27.2776 77.3462 24.7422C77.3266 24.5201 77.2717 24.3025 77.1836 24.0976C77.0543 24.4493 76.923 24.801 76.7917 25.1547C75.9316 27.5646 75.0675 29.9726 74.2201 32.3865C74.0908 32.7568 73.9262 32.9439 73.4952 32.9371C73.0837 32.9302 72.9358 32.7412 72.8162 32.3914C71.9483 29.8296 71.0636 27.2737 70.1839 24.7158C70.1219 24.5023 70.0165 24.3038 69.8743 24.1329C69.7339 25.1047 69.5935 26.0762 69.4531 27.0474C69.2023 28.7882 68.9446 30.5271 68.7076 32.2699C68.6605 32.6138 68.5195 32.7441 68.1736 32.7372C67.3977 32.7206 66.6209 32.7372 65.7646 32.7372C65.89 31.928 65.9939 31.2246 66.1085 30.5232C66.7525 26.5869 67.3968 22.6503 68.0414 18.7134C68.1678 17.9385 68.157 17.9238 68.9466 17.9395C70.469 17.9689 70.0869 17.6701 70.6355 19.1151C71.5838 21.6142 72.5096 24.1211 73.4462 26.6242C73.5079 26.7887 73.5755 26.9514 73.6872 27.2325C73.791 26.9768 73.8596 26.824 73.9154 26.6673C74.8951 23.936 75.8747 21.2076 76.8329 18.4695C76.9759 18.0609 77.1787 17.8817 77.6078 17.9316C77.8195 17.9439 78.0318 17.9439 78.2436 17.9316C79.1164 17.9316 79.1096 17.9316 79.232 18.7741C79.8773 23.2205 80.5239 27.6672 81.1718 32.1142C81.1869 32.3137 81.1896 32.5139 81.1796 32.7137Z" fill={fill}/>
    <path d="M98.6233 18.3226V32.9901C97.962 32.9901 97.3203 33.0146 96.6835 32.9715C96.5395 32.9617 96.3896 32.7334 96.277 32.5796C94.0414 29.6106 91.8084 26.6389 89.5781 23.6647C89.4713 23.5226 89.3586 23.3865 89.1588 23.1327C89.1431 23.4149 89.1274 23.5765 89.1274 23.7372C89.1274 26.509 89.1274 29.2798 89.1274 32.0496C89.1274 32.7256 89.1274 32.7265 88.4416 32.7285C87.7314 32.7285 87.0202 32.7285 86.2805 32.7285V17.9484C86.9349 17.9484 87.5639 17.9239 88.1899 17.966C88.3378 17.9758 88.4946 18.1884 88.6072 18.3383C90.8435 21.3053 93.0768 24.277 95.3071 27.2532C95.4299 27.4323 95.5904 27.5825 95.7773 27.6931V27.1395C95.7773 24.418 95.7773 21.6965 95.7773 18.975C95.7773 18.3216 95.7773 18.3206 96.4288 18.3197C97.142 18.3226 97.8542 18.3226 98.6233 18.3226Z" fill={fill}/>
    <path d="M14.8977 3.27116L13.6143 4.68383C13.1353 4.23906 12.717 3.7796 12.2301 3.41027C8.75619 0.784779 3.84025 2.30913 2.43346 6.43252C1.11876 10.2894 3.51109 14.4883 7.39936 15.1633C9.96901 15.609 12.7121 13.9093 13.4272 11.421C13.5017 11.1594 13.5252 10.8851 13.5967 10.5001H9.41354C9.41354 9.89268 9.41354 9.3911 9.41354 8.88951C9.41354 8.54859 9.67217 8.56622 9.90337 8.56524C10.9967 8.56524 12.089 8.56524 13.1823 8.56524C13.8837 8.56524 14.5852 8.58484 15.2856 8.55937C15.7353 8.54271 15.8734 8.71513 15.8803 9.15696C15.9224 12.5789 14.0101 15.5346 11.0065 16.6572C7.84706 17.8328 4.22918 16.86 2.05531 14.2404C-1.64487 9.77904 -0.178313 3.3172 5.08442 0.884705C8.29477 -0.596542 12.6445 0.415447 14.832 3.15556C14.8584 3.19136 14.8805 3.23016 14.8977 3.27116Z" fill={fill}/>
    <path d="M80.617 9.80657L85.6476 16.7769C84.7512 16.7769 84.0105 16.7974 83.2728 16.7582C83.1298 16.7504 82.979 16.5251 82.8683 16.3664C81.4771 14.3751 80.0903 12.3805 78.7076 10.3826C78.6155 10.2494 78.5274 10.0946 78.3981 10.0113C77.9984 9.75171 77.5947 9.99075 77.5938 10.4806C77.5938 12.3723 77.5791 14.263 77.6016 16.1538C77.6075 16.6358 77.4752 16.8533 76.9687 16.8013C76.6426 16.7777 76.3152 16.7777 75.9891 16.8013C75.6491 16.816 75.5188 16.6564 75.5218 16.334C75.5277 15.7796 75.5218 15.2251 75.5218 14.6686C75.5218 10.2184 75.5218 5.76775 75.5218 1.31683C75.5218 0.533101 75.5218 0.511548 76.2996 0.533101C77.7162 0.573267 79.1485 0.520365 80.5445 0.719236C82.8349 1.04546 84.3309 2.75595 84.421 4.9063C84.519 7.28296 83.2268 9.05909 80.9373 9.69097C80.8609 9.7135 80.7874 9.74583 80.617 9.80657ZM77.5928 5.2678C77.5928 6.08092 77.6006 6.89502 77.5928 7.70813C77.5928 8.02946 77.6907 8.21266 78.0385 8.18033C78.8458 8.1049 79.6697 8.1049 80.4563 7.93542C81.7798 7.65034 82.4568 6.72358 82.4715 5.36969C82.4872 3.9531 81.8553 3.03417 80.5122 2.7148C79.7284 2.52769 78.9055 2.50418 78.1002 2.41013C77.7084 2.36409 77.5859 2.55316 77.5947 2.92543C77.6055 3.70524 77.5928 4.48701 77.5928 5.2678Z" fill={fill}/>
    <path d="M99.6042 18.3227H100.398C102.404 18.3227 104.414 18.3344 106.418 18.3139C106.879 18.309 107.073 18.4402 107.04 18.9193C107.012 19.3914 107.012 19.8647 107.04 20.3369C107.058 20.7424 106.891 20.8845 106.494 20.8786C105.336 20.8649 104.177 20.8904 103.019 20.8668C102.576 20.858 102.411 21.0001 102.432 21.4546C102.462 22.2384 102.439 23.0152 102.439 23.8529C102.673 23.8705 102.86 23.8979 103.047 23.8989C104.14 23.8989 105.233 23.9146 106.326 23.894C106.729 23.8862 106.884 24.0223 106.864 24.4289C106.838 24.9334 106.839 25.4399 106.864 25.9444C106.881 26.3363 106.705 26.4578 106.34 26.4548C105.247 26.445 104.154 26.4754 103.061 26.4421C102.556 26.4264 102.415 26.6047 102.431 27.0897C102.463 28.0811 102.44 29.0744 102.44 30.1188C102.674 30.1413 102.862 30.1736 103.049 30.1746C104.191 30.1795 105.333 30.1913 106.478 30.1746C106.905 30.1668 107.065 30.3265 107.045 30.7467C107.021 31.2669 107.035 31.7901 107.038 32.3142C107.038 32.5904 106.93 32.7423 106.635 32.7423C104.383 32.7384 102.132 32.7354 99.8805 32.7335C99.7887 32.7217 99.6984 32.7004 99.6111 32.6698L99.6042 18.3227Z" fill={fill}/>
    <path d="M95.8963 2.42173C95.5965 2.44133 95.3722 2.46778 95.1488 2.46778C93.3707 2.46778 91.5927 2.48149 89.8155 2.46288C89.3718 2.45798 89.214 2.59415 89.2278 3.05067C89.2562 4.14202 89.2356 5.23434 89.2385 6.32666C89.2385 6.48634 89.2581 6.64505 89.2709 6.85176C89.506 6.86939 89.7107 6.89878 89.9155 6.89976C91.6446 6.89976 93.3737 6.91544 95.1018 6.89388C95.575 6.88801 95.7572 7.04867 95.7092 7.51499C95.6836 7.7756 95.6836 8.0381 95.7092 8.29872C95.7523 8.73956 95.5505 8.85908 95.1381 8.85516C93.3933 8.84047 91.6475 8.84831 89.9027 8.85027C89.2405 8.85027 89.2385 8.85027 89.2375 9.48803C89.2375 11.0855 89.2375 12.683 89.2375 14.2805C89.2375 14.4559 89.2522 14.6322 89.263 14.8556C89.5001 14.8742 89.7049 14.9026 89.9096 14.9026C91.7034 14.9026 93.4981 14.9183 95.2919 14.8967C95.7601 14.8909 95.9139 15.0378 95.9081 15.509C95.8934 16.8022 95.9081 16.8022 94.609 16.8022C92.3411 16.8022 90.0742 16.7904 87.8072 16.812C87.3174 16.812 87.1548 16.6787 87.1558 16.1771C87.1714 11.1574 87.1714 6.13726 87.1558 1.11683C87.1558 0.674019 87.2919 0.522172 87.7436 0.52903C90.2724 0.546663 92.8009 0.546663 95.3291 0.52903C95.7415 0.52903 95.9335 0.638753 95.9012 1.08156C95.8728 1.49694 95.8963 1.91917 95.8963 2.42173Z" fill={fill}/>
    <path d="M107.019 0.590819V2.42279C106.762 2.43944 106.539 2.46785 106.315 2.46785C104.537 2.46785 102.759 2.48058 100.981 2.46295C100.547 2.46295 100.372 2.57855 100.384 3.04389C100.415 4.13523 100.393 5.22755 100.395 6.31988C100.395 6.47956 100.413 6.63826 100.426 6.84791C100.658 6.8675 100.862 6.89788 101.066 6.89886C102.795 6.89886 104.524 6.91355 106.258 6.89297C106.724 6.8871 106.882 7.03405 106.877 7.50722C106.861 8.84642 106.877 8.84642 105.524 8.84642C104.006 8.84642 102.487 8.86014 100.972 8.83858C100.521 8.83173 100.384 8.98259 100.39 9.42638C100.413 11.0565 100.398 12.6867 100.4 14.3168C100.4 14.4765 100.414 14.6372 100.423 14.8478C100.657 14.8664 100.861 14.8968 101.064 14.8968C102.859 14.8968 104.654 14.9115 106.452 14.8909C106.912 14.885 107.081 15.0163 107.076 15.4964C107.06 16.7964 107.076 16.7964 105.783 16.7964C103.516 16.7964 101.249 16.7856 98.9806 16.8052C98.5055 16.8052 98.3213 16.6906 98.3223 16.1792C98.3393 11.1587 98.3415 6.13798 98.3291 1.1169C98.3291 0.774995 98.3458 0.519303 98.8092 0.522242C101.403 0.536937 104.001 0.528122 106.591 0.531061C106.734 0.543227 106.877 0.563175 107.019 0.590819Z" fill={fill}/>
    <path d="M55.4495 12.1508C55.4927 12.3732 55.5211 12.4976 55.5397 12.624C55.8443 14.6538 58.126 15.8157 59.9481 14.8674C62.0152 13.7898 62.1651 10.9487 60.1823 9.732C59.424 9.26666 58.5619 8.97178 57.7635 8.56914C57.067 8.21744 56.342 7.89122 55.717 7.43469C53.7302 5.97892 53.6195 3.1379 55.4417 1.47248C57.1728 -0.109671 60.1235 -0.22919 61.931 1.25206C62.3846 1.62335 62.7304 2.12494 63.1879 2.63632L61.5401 3.63361C61.2922 3.36029 61.0934 3.10361 60.8543 2.88613C59.7542 1.86532 57.9203 1.90647 56.8573 2.95667C55.9423 3.86285 55.9893 5.14425 57.018 5.92896C57.4392 6.2493 57.9438 6.47169 58.4297 6.69505C59.2438 7.06928 60.0951 7.36416 60.8925 7.77268C63.0576 8.88165 63.9775 10.7675 63.5376 13.0697C63.1076 15.2906 61.3745 16.8551 59.0792 17.0932C56.3822 17.3724 54.1711 15.7951 53.5216 13.153C53.4158 12.7219 53.5137 12.5093 53.9644 12.4417C54.4464 12.3702 54.9186 12.2576 55.4495 12.1508Z" fill={fill}/>
    <path d="M43.9256 12.1507C43.9952 12.474 44.0393 12.712 44.0981 12.9462C44.29 13.6453 44.7257 14.2526 45.3265 14.6585C45.9272 15.0644 46.6533 15.2419 47.3736 15.1591C48.0938 15.0763 48.7607 14.7385 49.2536 14.2069C49.7465 13.6753 50.033 12.9848 50.0612 12.2604C50.1092 10.9927 49.497 10.1414 48.4399 9.58298C47.663 9.17251 46.8352 8.85804 46.0397 8.48087C45.5347 8.25213 45.044 7.99302 44.5702 7.70497C42.1348 6.16299 41.98 2.92815 44.2861 1.19807C46.125 -0.182272 49.4323 -0.363509 51.2476 2.10719C51.3622 2.26198 51.4582 2.43146 51.6169 2.67834L50.0495 3.63742C49.7468 3.32001 49.5107 3.0271 49.2285 2.7861C48.1147 1.83681 46.2082 1.98082 45.2345 3.07118C44.5105 3.88136 44.5007 4.96389 45.2962 5.70157C45.7047 6.07972 46.22 6.3648 46.7265 6.60971C47.6934 7.07897 48.726 7.42185 49.6655 7.93813C51.6189 9.00988 52.4634 10.8869 52.0451 13.003C51.6081 15.2062 49.9946 16.7668 47.8394 17.0686C45.016 17.4604 42.6335 15.8381 42.0192 13.1176C41.9291 12.7199 41.982 12.5063 42.4346 12.4475C42.9244 12.3829 43.4074 12.2545 43.9256 12.1507Z" fill={fill}/>
    <path d="M68.1353 9.57804C68.1353 7.41103 68.1245 5.24403 68.1441 3.07702C68.1441 2.60972 68.005 2.44513 67.5318 2.46081C66.6364 2.49118 65.7391 2.46081 64.8417 2.47453C64.439 2.48334 64.2461 2.37068 64.2539 1.92493C64.2882 0.259511 64.0687 0.544593 65.6048 0.535776C68.197 0.519122 70.7902 0.529898 73.3824 0.535776C74.0289 0.535776 74.0397 0.540675 74.0319 1.19411C74.0142 2.64597 74.2445 2.45787 72.7701 2.47256C72.1186 2.47942 71.4652 2.49608 70.8108 2.46669C70.3405 2.44513 70.1897 2.61755 70.1926 3.08485C70.2102 5.70838 70.2004 8.33192 70.2004 10.9545C70.2004 12.6983 70.1897 14.4421 70.2083 16.1849C70.2083 16.6385 70.0868 16.8168 69.6019 16.8128C68.1324 16.8021 68.1324 16.8197 68.1324 15.3502L68.1353 9.57804Z" fill={fill}/>
    <path d="M82.1582 32.7324V18.3735H84.9316C84.9616 18.5745 84.9812 18.7769 84.9904 18.9799C84.9904 23.3309 84.9904 27.6816 84.9904 32.032C84.9904 32.7177 84.9786 32.7314 84.2713 32.7324C83.564 32.7334 82.8841 32.7324 82.1582 32.7324Z" fill={fill}/>
    </svg>
    
  )
}
