import React from 'react'

export default function Up({ width = 19, height = 25 }) {
  return (
    <svg width={width} height={height} viewBox="0 0 19 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="path-1-inside-1_180_268" fill="white">
        <path d="M1.71696 12.3002L8.28517 5.09769L8.28517 25L10.7148 25L10.7148 5.09769L17.283 12.3002L19 10.4174L9.5 1.07683e-06L-6.32498e-07 10.4174L1.71696 12.3002Z" />
      </mask>
      <path d="M1.71696 12.3002L8.28517 5.09769L8.28517 25L10.7148 25L10.7148 5.09769L17.283 12.3002L19 10.4174L9.5 1.07683e-06L-6.32498e-07 10.4174L1.71696 12.3002Z" fill="#C4C4C4" />
      <path d="M1.71696 12.3002L-1.23861 14.9955L1.71696 18.2365L4.67254 14.9955L1.71696 12.3002ZM8.28517 5.09769L12.2852 5.09769L12.2852 -5.22486L5.3296 2.4024L8.28517 5.09769ZM8.28517 25L4.28517 25L4.28517 29L8.28517 29L8.28517 25ZM10.7148 25L10.7148 29L14.7148 29L14.7148 25L10.7148 25ZM10.7148 5.09769L13.6704 2.4024L6.71484 -5.22486L6.71483 5.09769L10.7148 5.09769ZM17.283 12.3002L14.3275 14.9955L17.283 18.2365L20.2386 14.9955L17.283 12.3002ZM19 10.4174L21.9556 13.1127L24.4135 10.4174L21.9556 7.72212L19 10.4174ZM9.5 1.07683e-06L12.4556 -2.69529L9.5 -5.93628L6.54443 -2.69529L9.5 1.07683e-06ZM-6.32498e-07 10.4174L-2.95557 7.72211L-5.4135 10.4174L-2.95557 13.1127L-6.32498e-07 10.4174ZM4.67254 14.9955L11.2407 7.79298L5.3296 2.4024L-1.23861 9.60489L4.67254 14.9955ZM4.28517 5.09769L4.28517 25L12.2852 25L12.2852 5.09769L4.28517 5.09769ZM8.28517 29L10.7148 29L10.7148 21L8.28517 21L8.28517 29ZM14.7148 25L14.7148 5.09769L6.71483 5.09769L6.71483 25L14.7148 25ZM7.75926 7.79298L14.3275 14.9955L20.2386 9.60489L13.6704 2.4024L7.75926 7.79298ZM20.2386 14.9955L21.9556 13.1127L16.0444 7.72212L14.3275 9.60489L20.2386 14.9955ZM21.9556 7.72212L12.4556 -2.69529L6.54443 2.69529L16.0444 13.1127L21.9556 7.72212ZM6.54443 -2.69529L-2.95557 7.72211L2.95557 13.1127L12.4556 2.69529L6.54443 -2.69529ZM-2.95557 13.1127L-1.23861 14.9955L4.67254 9.60489L2.95557 7.72212L-2.95557 13.1127Z" fill="#C4C4C4" mask="url(#path-1-inside-1_180_268)" />
    </svg>

  )
}
