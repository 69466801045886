import React from 'react'
import Layout from '../layout'
import Header from '../header'
import HeaderTitle from '../header-title'
import Tabs from '../tabs'
import Footer from '../footer'
import { graphql, useStaticQuery } from 'gatsby'
import Up from '../../icons/up'

export default function OtherPhotos() {

  const data = useStaticQuery(graphql`
 query OtherPhotos {
    datoCmsOtherSitePhoto {
      description
        categories {
          zipFile{
              url
              size
          }
          name
          images {
            title
            url
            width
            height
            notes
            
            gatsbyImageData(imgixParams: {auto:"compress",w:"1400",h:"1600",q:45})
            highRes:sizes(imgixParams: { dl: "", fm: "jpg",q:85}) {
              src
            }
            lowRes:sizes(imgixParams: {q: 45, dl: "", fm: "jpg" }) {
              src
            }
          }
        }
    }
  }
  `)
  return (
    <Layout>

      <Header title='Other Photos' className='md:hidden' categories={data.datoCmsOtherSitePhoto.categories} downloadAble={true} />
      <HeaderTitle className='max-w-620' content={data.datoCmsOtherSitePhoto.description} />
      <Tabs categories={data.datoCmsOtherSitePhoto.categories} />
      <Footer >

        <button onClick={() => { window.scrollTo({ left: 0, top: 0, behavior: 'smooth' }) }} className="flex  items-center ">
          <Up />
          <h1 className='h1 ml-20'>Back to top</h1>
        </button>
      </Footer>
    </Layout>
  )
}

