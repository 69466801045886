import React, { useContext } from 'react'
import { Router } from "@reach/router"
import OtherPhotos from '../components/pages/other-photos'
import Photos from '../components/pages/photos'
import Films from '../components/pages/films'
import SignIn from '../components/pages/sign-in'
import AuthContext, { AuthContextWrapper } from '../context/auth'
import Seo from '../components/seo'
import Intro from '../components/pages/intro'
import Presentation from '../components/pages/presentations'
import Ebook from '../components/pages/e-book'

export default function Index() {

  return (
    <AuthContextWrapper>
      <Seo title="Photo Gallery" />
      <div className='app'>
        <form hidden name="email" method="POST" data-netlify="true"  >
          <input type="hidden" name="form-name" value="email" />
          <input hidden name='email' type='email' required={true} placeholder='Enter Email' />
        </form>
        <Router basepath='/'>
          <PrivateRoute Component={OtherPhotos} path='/other-photos' />
          <PrivateRoute Component={Photos} path='/photos' />
          <PrivateRoute Component={Films} path='/films' />
          <PrivateRoute Component={Presentation} path='/presentations' />
          <PrivateRoute Component={Ebook} path='/ebook' />
          <PrivateRoute Component={Intro} path='/intro' />
          <PublicRoute path='/' Component={SignIn} />
        </Router>
      </div>
    </AuthContextWrapper>

  )
}


function PublicRoute({ Component, location, route, ...rest }) {
  const { user } = useContext(AuthContext)

  if (isLoggedIn(user, location) && location.pathname === '/') {
    return <Intro />
  } else {
    return <Component {...rest} />
  }
}

function PrivateRoute({ Component, location, ...rest }) {
  const { user } = useContext(AuthContext)

  if (!isLoggedIn(user, location) && location.pathname !== '/') {
    return <SignIn />
  }

  return <Component {...rest} />
}

function isLoggedIn(user, location) {

  if (user || location.state?.user) {
    return true
  }

  return false
}



