import React from 'react'
import Header from '../header'
import Layout from '../layout'
import Footer from '../footer'
import { useStaticQuery, graphql } from 'gatsby'
import HeaderTitle from '../header-title'
import Up from '../../icons/up'
import ImageWrapper from '../image-wrapper'
import { styled } from 'styled-components'
import Video from '../video'
export default function Presentation() {
    const data = useStaticQuery(graphql`
        query Presentation {
         
            datoCmsPresentation {
                description
                presentation {
                name
                thumbnail {
                    gatsbyImageData
                    url
                }
                content {
                    url
                }
                presentationVideoLink
            }
        }
      }
      `)



    return (
        <Layout>
            <Header downloadAble={false} title='Presentations' className='md:hidden' />
            <HeaderTitle className='max-w-620' content={data.datoCmsPresentation.description} />
            <div className='p-40 pt-0  md:p-30 md:pt-50 md:pb-0 pl-[30px] pr-[30px] '>
                <div className='grid grid-cols-3  md:flex md:flex-col gap-x-[20px] gap-y-[20px] '>

                    {data.datoCmsPresentation.presentation.map((image, index) => {
                        return (
                            <div className=' relative pt-[66.66%] md:w-[100%] h-full'>
                                <div className='top-0 w-full h-full absolute'>
                                    {image.presentationVideoLink ?
                                        <Video  small className='h-full' film={{
                                            name: image.name,
                                            thumbnail: image.thumbnail,
                                            videoLink:image.presentationVideoLink
                                        }} /> :
                                        <ImageWrapper
                                            className='h-full'
                                            key={index}
                                            single={true}
                                            title={image.name}
                                            href={image.content?.url}
                                            text={'View PDF'}
                                            image={image.thumbnail} />}
                                </div>
                            </div>
                        )
                    })}

                </div>
            </div>

            <Footer>
                <button onClick={() => { window.scrollTo({ left: 0, top: 0, behavior: 'smooth' }) }} className="flex  items-center ">
                    <Up />
                    <h1 className='h1 ml-20'>Back to top</h1>
                </button>
            </Footer>
        </Layout>
    )
}




