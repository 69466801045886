import React from "react"

const Cross = (props) => (
  <svg
    width={20}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      stroke="#888"
      strokeWidth={1.133}
      d="M.599 18.621 18.23.99M1.401.621l17.63 17.63"
    />
  </svg>
)

export default Cross
