
import React, { useState, useRef, useLayoutEffect, useCallback, useEffect } from 'react'
import styled, { keyframes } from "styled-components"
import Down from '../icons/down'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Duration } from 'luxon'
import Play from '../icons/play';
import Paused from '../icons/paused';
import { SwitchTransition, Transition } from 'react-transition-group';
import 'video-react/dist/video-react.css'

export default function Video({ film, small, className }) {
  const ref = useRef(null)
  const [duration, setDuration] = useState(0)
  const [playing, setPlaying] = useState(false)
  const [hasPlayed, setHasPlayed] = useState(false)

  function play() {
    if (ref.current.paused) {
      ref.current.play()
      setPlaying(true)
    } else {
      ref.current.pause()
      setPlaying(false)
    }
  }

  useEffect(() => {
    ref.current.addEventListener('pause', () => setPlaying(false));
    ref.current.addEventListener('play', () => {
      setHasPlayed(true)
      setPlaying(true)
    });
    ref.current.addEventListener('durationchange', () => setDuration(ref.current.duration));

    return () => {
      ref.current.removeEventListener('pause', () => setPlaying(false));
      ref.current.removeEventListener('play', () => {
        setHasPlayed(true)
        setPlaying(true)
      });
      ref.current.removeEventListener('durationchange', () => setDuration(ref.current.duration));
    }
  }, [])



  return (
    <VideoWrapper className={className} small={small} >
      <Overlay onClick={play} className='' small={small} playing={playing} >
        <div className='text'>
          <h3 className={' white  text-left md:text-[13px] md:font-medium ' + (small ? 'h3 mb-10' : 'h2 mb-20')}>
            {film.name}
          </h3>
          <Description small={small} className={'h3 white md:hidden ' + (small ? 'max-w-200' : ' max-w-250')}>
            {film.description}
          </Description>
        </div>
        <div className='hidden md:block' />
        <div className='w-[100%] flex justify-between'>
          <h3 className='h3 white flex items-center'>{duration !== 0 ? Duration.fromObject({ seconds: Number(duration), }).toFormat('hh:mm:ss') : ''}</h3>
          {film.downloadLink ? <DownloadButton download href={film.downloadLink} className='flex'>
            <h3 className='h3 white medium mr-[10px]'>Download</h3>
            <div className='download'>
              <Down fill='black' />
            </div>
          </DownloadButton> : null}
        </div>
      </Overlay>

      <SwitchTransition>
        <Transition timeout={250} key={playing}>
          {state => (

            <PlayWrapper style={{ ...transitionStyles[state] }} small={small} playing={playing} stage={state} >
              {!playing ? <Play /> : <Paused />}
            </PlayWrapper>
          )}
        </Transition>
      </SwitchTransition>

      <BgImage playing={playing}>
        {!hasPlayed && (
          <Thumbnail className='abs'>
            <GatsbyImage className='h-full w-full' image={film.thumbnail.gatsbyImageData} />
          </Thumbnail>
        )}
        <video ref={ref} width='100%' height='100%' src={film.videoLink} playsInline loop={true} controls={playing} />
      </BgImage>



    </VideoWrapper>
  )
}



const Thumbnail = styled.div`
  position:absolute ;
  height:100%;
  width:100%;
  z-index:20;
`

const transitionStyles = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

const fade = keyframes`
      from {
          opacity:0;
      }
      
      to{
          opacity:1 ;
      }
  `



const VideoWrapper = styled.div`
    padding-top:${props => props.small ? 66.67 : 50}%;
    overflow:hidden ;
    border-radius: 5px;
   -webkit-transform: translateZ(0);
   animation:${fade} 0.5s ease ;
    @media(max-width:800px){
      padding-top:66.67%
    }
  `



const PlayWrapper = styled.div`
  pointer-events:none;
    height: ${props => props.small ? 40 : 80}px;
    width: ${props => props.small ? 40 : 80}px;
    border:${props => !props.playing ? '1px solid white' : ''} ;
    border-radius:50%;
   -webkit-transform: translateZ(0);
    position:absolute ;
    margin:auto;
    top:50% ;
    left:50%;
    z-index:10;
    transform:translate(-50%,-50%);
    display:flex ;
    justify-content:center;
    align-items:center;
    transition: opacity 0.5s ease, background-color 0.5s ease, border 0.5s ease;
    background-color:transparent ;
  
    path,circle,rect{
      fill:white ;
      opacity:${props => props.playing ? 0 : 1}
    
    }
  
    ${VideoWrapper}:hover &{
      background-color:white ;
      path,circle,rect{
        fill:black ;
        opacity:1 ;
      
      }
    }
  
    @media(max-width:800px){
      display:${props => props.playing ? 'none' : ''} ;
      height:30px;
      width:30px;
    }
   
  `

const BgImage = styled.div`
z-index:2;
    position:absolute ;
    top:0px ;
    object-fit:cover;
    height:100% ;
    width:100%;
    video{
      width:100%;
      height:100%;
      object-fit:cover;
    }
    .video-react-loading-spinner,.video-react-big-play-button,.video-react-bezel{
      display:none !important;
    }
    .video-react-control-bar{
  
      background:black ;
      transition:0.25s ease opacity ;
      opacity:${props => props.playing ? 1 : 0} ;
    }
    .video-react-poster{
      background-size:cover !important;
    }
  `

const Description = styled.p`
    @media(max-width:1600px){
      display:${props => props.small ? 'none' : ''} ;
    }
    text-align:left;
  
   
  `

const DownloadButton = styled.a`
    opacity:0 ;
    transition: 1s ease opacity ;
    ${VideoWrapper}:hover & {
      opacity:1 ;
    }
    align-items:center;
  
  
    svg{
      path,circle,rect{
        transition: 0.5s ease fill ;
      }
    }
    .download{
      transition: 1s ease background-color ;
    }
  
    &:hover{
     
      svg{
        path{
          fill:white ;
        }
      }
      .download{
        background-color:black ;
      }
    }
  `

const Overlay = styled.button`
    z-index:10;
    padding:${props => props.small ? 20 : 25}px;
    position:absolute ;
    top:0px ;
    width:100% ;
    height:100% ;
    display:flex ;
    flex-direction:column;
    justify-content:space-between;
    transition:0.5s ease opacity;
    background-color:#00000020 ;
    opacity:${props => props.playing ? 0 : 1} ;
    pointer-events:${props => props.playing ? 'none' : 'all'};
  
    @media(max-width:800px){
      ${DownloadButton}{
        display:none;
      }
    }
  
  `