import React, { useCallback, useMemo, useState } from 'react'
import { CSSTransition, SwitchTransition, } from 'react-transition-group';
import { chunk, flatmap } from 'lodash'
import styled from "styled-components"
import Down from '../icons/down';
import ImageWrapper from './image-wrapper';

export default function Tabs({ categories }) {

    const [currentFilter, setCurrentFilter] = useState('all')

    const totalNumber = useMemo(() => {
        return categories.reduce((prev, curr) => {
            return prev + curr.images.length
        }, 0)
    }, [categories])

    const transformedCategories = useMemo(() => {
        const categoriesSet = new Map()
        categories.forEach((category) => {
            categoriesSet.set(category.name, category.images)
        })
        categoriesSet.set('all', flatmap(categories.map((cat) => cat.images)))
        return categoriesSet
    }, [categories])

    return (
        <div className='p-40 pt-0  md:p-30 md:pt-50 md:pb-0 '>
            <FilterButtons total={totalNumber} categories={categories} currentFilter={currentFilter} setCurrentFilter={setCurrentFilter} />
            <ImageGrid categories={transformedCategories} currentFilter={currentFilter} />
        </div>
    )
}

function FilterButtons({ categories, setCurrentFilter, currentFilter, total }) {
    const [height, setHeight] = useState(0)
    const [open, setOpen] = useState(false)

    const dropDown = useCallback((node) => {
        if (node !== null) {

            setHeight(node.scrollHeight)
        }
    }, [])



    return (
        <div className='flex pb-20 md:flex-col md:items-center md:pb-50 items-start '>
            <button onClick={() => setOpen((state) => !state)} className='h3 medium mr-10 md:mr-0  flex items-center leading-6 '>
                Filter:
                <Arrow open={open} className='hidden md:block ml-5'>
                    <Down />
                </Arrow>
            </button>
            <Dropdown open={open} innerHeight={height} ref={dropDown} className='flex md:flex-col  flex-wrap md:flex-nowrap '>
                <button onClick={() => setCurrentFilter('all')} className={'h3 medium mr-[20px]  md:mb-10 md:mr-0 leading-6 md:mt-10 ' + (currentFilter === 'all' ? '' : 'light')}>
                    All ({total})
                </button>
                {categories.map(({ name, images }, index) => {
                    return (
                        <button key={index} onClick={() => setCurrentFilter(name)} className={'h3 medium mr-[20px]  md:mb-10 md:mr-0 leading-6 ' + (currentFilter === name ? '' : 'light')}>
                            {name} ({images.length})
                        </button>
                    )
                })}
            </Dropdown>
        </div>
    )
}

const Dropdown = styled.div`
    @media(max-width:800px){
        overflow:hidden ;
        transition:1s ease height ;
        height:${props => props.open ? props.innerHeight : 0}px ;
    }
`


const Arrow = styled.div`
    transform:${props => props.open ? 'rotate(180deg)' : 'rotate(0)'} ;
`



function ImageGrid({ categories, currentFilter }) {

    const currentImages = useMemo(() => {
        return categories.get(currentFilter)
    }, [categories, currentFilter])

    return (
        <SwitchTransition>
            <CSSTransition classNames='fade' key={currentFilter} timeout={{
                exit: 500,
                enter: 500
            }}>

                <Grid currentImages={currentImages} />

            </CSSTransition>
        </SwitchTransition>
    )
}



function Grid({ currentImages }) {

    const [currentChunk, setCurrentChunk] = useState(0)

    function changeChunk(fn) {
        setCurrentChunk((state) => fn(state))
        window.scrollTo(0, 0)
    }
    const chunks = useMemo(() => {
        return chunk(currentImages, 20)
    }, [currentImages])

    const currentImagesChunks = useMemo(() => {
        return chunks[currentChunk]
    }, [currentChunk, chunks])

    return (
        <>
            <div className='css-grid '>
                {currentImagesChunks.map((image, index) => {
                    return (
                        <ImageWrapper
                            key={image.url + currentChunk + index}
                            index={index}
                            className={' card  ' + (image.width < image.height ? 'card-large' : 'card-small')}
                            title={image.title}
                            highRes={image.highRes.src}
                            lowRes={image.lowRes.src}
                            image={image}
                        />
                    )
                })}
            </div>
            <div className='flex justify-center pt-[60px]'>
                <button className={currentChunk !== 0 ? '' : 'pointer-events-none opacity-0'} onClick={() => changeChunk((state) => Math.max(state - 1, 0))}>
                    Prev
                </button>
                <span className='pl-[20px] pr-[20px]'>
                    {currentChunk + 1} of {chunks.length}
                </span>

                <button className={chunks.length !== currentChunk + 1 ? '' : 'pointer-events-none opacity-0'} onClick={() => changeChunk((state) => Math.min(state + 1, chunks.length - 1))}>
                    Next
                </button>
            </div>
        </>
    )
}