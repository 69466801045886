import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
export default function Footer({ children }) {

    const data = useStaticQuery(graphql`
        query Disclaimer {
            datoCmsDisclaimer {
                disclaimer
            }
        }
    `)
    return (
        <div>

            {children ? <div className=' pt-150 pb-150 flex justify-center items-center md:pb-75 md:pt-75'>
                {children}

            </div> : null}

            <div className='flex flex-col justify-center  pl-30 pr-30  pb-30 '>
                <h3 className='h3 light m-auto flex items-center '>
                    Disclaimer

                </h3>
                <br />
                <div >
                    <h3 className='h3 max-w-780 text-center light m-auto'>
                        {data.datoCmsDisclaimer.disclaimer}
                    </h3>
                </div>
            </div>
        </div>
    )
}
