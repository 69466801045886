import React from 'react'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'

export default function HeaderTitle({ content, className }) {
    return (
        <div className='flex justify-center pt-40 pb-40 md:pt-50  md:pb-0 md:pl-30 md:pr-30  '>
            <ReactMarkdown className={'text-center h3 links-underline p-mb-10  ' + className + ' '}>
                {content}
            </ReactMarkdown>
        </div>
    )
}
